import React, { useState } from 'react';
import './ChartConfigurationType.scss';
import { FlightTabs, getIcon } from '@flybits/design-system';
import { ReactComponent as RecommendedIcon } from 'assets/icons/recommended.svg';
const MAIN_CLASS = 'chart-config-selector';
const CLASSES = {
  TABS: `${MAIN_CLASS}__tabs`,
};
interface ChartConfigurationTypeProps {
  currrentSelection?: string;
  onSelect?: (s: string) => void;
}
export default function ChartConfigurationType({ currrentSelection, onSelect }: ChartConfigurationTypeProps) {
  const [selectedTab, setSelectedTab] = useState<string>(currrentSelection || 'recommended');
  const selectTab = (selected: string) => {
    setSelectedTab(selected);
    onSelect && onSelect(selected);
  };
  return (
    <div className={MAIN_CLASS}>
      <FlightTabs
        labels={[
          {
            key: 'recommended',
            name: (
              <>
                <RecommendedIcon /> Recommendations
              </>
            ),
          },
          { key: 'diy', name: <>{getIcon('build', {})} Do it yourself</> },
        ]}
        className={CLASSES.TABS}
        selectedTab={selectedTab}
        onSelect={(e: { key: string }) => {
          selectTab(e.key);
        }}
      />
    </div>
  );
}
