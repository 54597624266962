/* eslint-disable no-console */
import React, { useState } from 'react';
import './CreateOrEditChart.scss';
import { FlightButton } from '@flybits/design-system';
import { useHistory, useRouteMatch } from 'react-router-dom';
import Preview from '../Shared/Preview/Preview';
import ExperienceSelector from './ExperienceSelector/ExperienceSelector';
import ChartConfigurationType from './ChartConfigurationType/ChartConfigurationType';
import RecommendationsSelector from './RecommendationsSelector/RecommendationsSelector';
import TimeFrameSelector from './TimeFrameSelector/TimeFrameSelector';
import ChartTitleInput from './ChartTitleInput/ChartTitleInput';
import JourneyAnalyticsCollapsible from 'pages/AnalyticsV2/ExperiencesDashboard/Shared/JourneyAnalyticsCollapsible/JourneyAnalyticsCollapsible';
import { JourneyAnalyticsCollapsibleModes } from 'pages/AnalyticsV2/ExperiencesDashboard/types';
import { ReactComponent as IconRecommendation } from 'assets/icons/icon-recommendation.svg';

const MAIN_CLASS = 'chart-details';
const CLASSES = {
  HEADER: `${MAIN_CLASS}__header`,
  BACK: `${MAIN_CLASS}__header__back`,
  CONTENT: `${MAIN_CLASS}__content`,
  COLUMN: `${MAIN_CLASS}__content__column`,
  FOOTER: `${MAIN_CLASS}__footer`,
};
interface RouteMatch {
  pid?: string;
  id?: string;
}
export default function CreateOrEditChart() {
  const { id }: RouteMatch = useRouteMatch().params;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const isEditMode = !!id;
  const history = useHistory();

  const [isExperienceSelectorCompleted, setExperienceSelectorCompleted] = useState(false);
  const [isRecommendationSelectorCompleted, setRecommendationSelectorCompleted] = useState(false);
  const [isTimeframeSelectorCompleted, setTimeframeSelectorCompleted] = useState(false);
  const [isChartTitleInputCompleted, setChartTitleInputCompleted] = useState(false);

  const handleApplyExperienceSelector = () => setExperienceSelectorCompleted(true);
  const handleApplyRecommendationSelector = () => setRecommendationSelectorCompleted(true);
  const handleApplyTimeframeSelector = () => setTimeframeSelectorCompleted(true);
  const handleApplyChartTitleInput = () => setChartTitleInputCompleted(true);

  const [chartTitle, setChartTitle] = useState('');

  return (
    <div className={MAIN_CLASS}>
      <header className={CLASSES.HEADER}>
        <FlightButton
          className={CLASSES.BACK}
          iconLeft={'baselineKeyboardArrowLeft'}
          theme="link"
          onClick={() => history.goBack()}
        />
      </header>
      <div className={CLASSES.CONTENT}>
        <div className={CLASSES.COLUMN}>
          <JourneyAnalyticsCollapsible
            key={1}
            step={1}
            mode={
              !isExperienceSelectorCompleted
                ? JourneyAnalyticsCollapsibleModes.DRAFT
                : JourneyAnalyticsCollapsibleModes.COMPLETED
            }
            title={'Select an experience'}
            label={
              <span>
                Based on&nbsp;<b>RewardMax Platinum</b>
              </span>
            }
            actionLabel={'Apply'}
            actionHandler={handleApplyExperienceSelector}
          >
            <ExperienceSelector
              experiences={[
                { key: '12345', name: 'Vanilla' },
                { key: '43563456', name: 'Strawberry' },
                { key: '1h6htf672345', name: 'Chocolate' },
              ]}
            />
          </JourneyAnalyticsCollapsible>
          <ChartConfigurationType />
          <JourneyAnalyticsCollapsible
            key={2}
            step={2}
            mode={
              !isRecommendationSelectorCompleted
                ? JourneyAnalyticsCollapsibleModes.DRAFT
                : JourneyAnalyticsCollapsibleModes.COMPLETED
            }
            title={'Select a recommendation'}
            titleIcon={<IconRecommendation />}
            description={'Make chart building easy by selecting a pre-defined option'}
            label={
              <span>
                For the selected experience, show me&nbsp;
                <b>push notifications sent</b>
                &nbsp;in the&nbsp;
                <b>past 7 days</b>
              </span>
            }
            actionLabel={'Apply'}
            actionHandler={handleApplyRecommendationSelector}
          >
            <RecommendationsSelector onChange={() => null} />
          </JourneyAnalyticsCollapsible>
          <JourneyAnalyticsCollapsible
            key={3}
            step={3}
            mode={
              !isTimeframeSelectorCompleted
                ? JourneyAnalyticsCollapsibleModes.DRAFT
                : JourneyAnalyticsCollapsibleModes.COMPLETED
            }
            title={'Select a time frame (horizontal)'}
            label={
              <span>
                Over the past&nbsp;
                <b>7 days</b>
                &nbsp;at a&nbsp;
                <b>daily</b>
                &nbsp;interval
              </span>
            }
            actionLabel={'Apply'}
            actionHandler={handleApplyTimeframeSelector}
          >
            <TimeFrameSelector onChange={() => null} />
          </JourneyAnalyticsCollapsible>
          <JourneyAnalyticsCollapsible
            key={4}
            step={4}
            mode={
              !isChartTitleInputCompleted
                ? JourneyAnalyticsCollapsibleModes.DRAFT
                : JourneyAnalyticsCollapsibleModes.COMPLETED
            }
            title={'Title your chart'}
            label={<span>{chartTitle}</span>}
            actionLabel={'Apply'}
            actionHandler={handleApplyChartTitleInput}
          >
            <ChartTitleInput title={chartTitle} onChange={(title) => setChartTitle(title)} />
          </JourneyAnalyticsCollapsible>
        </div>
        <div className={CLASSES.COLUMN}>
          <Preview />
        </div>
      </div>
      <footer className={CLASSES.FOOTER}>
        <FlightButton theme="secondary" onClick={() => history.goBack()} label={'Cancel'} />
        <FlightButton
          theme="primary"
          onClick={() => console.log('TODO: Save to dashboard')}
          label={'Save to dashboard'}
          disabled={false}
        />
      </footer>
    </div>
  );
}
