export const RECOMMENDED_METRICS = [
  {
    icon: 'phoneSetup',
    metric: { key: 'push-sent', name: 'push notifications sent' },
    time: { period: 7, interval: 'days' },
  },
  {
    icon: 'pushNotification',
    metric: { key: 'push-engagement', name: 'push engagement rate' },
    time: { period: 7, interval: 'days' },
  },
];
