import { isEqual } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FlightDateRangePicker, getIcon } from '@flybits/design-system';
import { ReactComponent as AndroidGray } from 'assets/icons/android_gray.svg';
import { ReactComponent as IosGray } from 'assets/icons/ios_gray.svg';
import { ReactComponent as WebGray } from 'assets/icons/web_gray.svg';
import { ReactComponent as IosBlue } from 'assets/icons/ios_blue.svg';
import { ReactComponent as AndroidGreen } from 'assets/icons/android_green.svg';
import { ReactComponent as WebBlue } from 'assets/icons/web_blue.svg';

import { AnalyticsGraphs } from 'components/Analytics/AnalyticsOverviewGraphs/AnalyticsGraphs';
import { GraphData, AnalyticsOverview as TAnalyticsOverview } from 'components/Analytics/types';
import LoadingState from 'components/Performance/LoadingState/LoadingState';
import { ErrorPage } from 'components/Shared/ErrorPage/ErrorPage';
import useFeatureFlag from 'hooks/useFeatureFlag';
import AnalyticsExperiencesAPI from 'services/api/analytics-experiences.api';
import Engagement from '../../AnalyticsV2/AnalyticsDashboard/Engagement/Engagement';
import ExportEngagement from '../../AnalyticsV2/AnalyticsDashboard/Engagement/ExportEngagement';
import './AnalyticsOverview.scss';
import TemplatedExperienceAnalyticsAPI from 'services/api/analytics.api';
import { ModuleConfig } from 'interface/templated-experience/analytics.interface';
import { useQuery } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { showSnackbar } from 'store/snackbar/snackbar.action';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import PieGraphDevice from 'components/Shared/Graphs/PieGraphDevice';
import { AdvancedDateRange } from '../../AnalyticsV2/AnalyticsDashboard/types';
import useDelayedLoadingState from 'hooks/useDelayedLoading';
import { epochToDateTimeString, intlNumberFormat } from 'helpers/common.helper';
import JourneyTable from '../../AnalyticsV2/AnalyticsDashboard/JourneyTable/JourneyTable';
import { DATE_FORMAT_OPTIONS } from '../../AnalyticsV2/AnalyticsDashboard/constants';

const CLASS_ANALYTICS_OVERVIEW_DEFAULT = 'analytics-overview';
const ANALYTICS_OVERVIEW = {
  BOTTOM_SIDE: `${CLASS_ANALYTICS_OVERVIEW_DEFAULT}__bottom-side`,
  TOP_SIDE: `${CLASS_ANALYTICS_OVERVIEW_DEFAULT}__top-side`,
  TOP_SIDE_HEADER: `${CLASS_ANALYTICS_OVERVIEW_DEFAULT}__top-side__header`,
  TOP_SIDE_HEADER_DATE: `${CLASS_ANALYTICS_OVERVIEW_DEFAULT}__top-side__header__date`,
  TOP_SIDE_HEADER_DATE_TITLE: `${CLASS_ANALYTICS_OVERVIEW_DEFAULT}__top-side__header__date__title`,
  TOP_SIDE_HEADER_DATE_DROPDOWN: `${CLASS_ANALYTICS_OVERVIEW_DEFAULT}__top-side__header__date__dropdown`,
  SPINNER: `${CLASS_ANALYTICS_OVERVIEW_DEFAULT}__spinner`,
};

const ENGAGEMENT = `${CLASS_ANALYTICS_OVERVIEW_DEFAULT}__engagement`;
const ENGAGEMENT_CONTAINER = {
  SELF: `${ENGAGEMENT}__container`,
  HEADER: `${ENGAGEMENT}__header`,
};

const METRICS = `${CLASS_ANALYTICS_OVERVIEW_DEFAULT}__metrics`;
const METRIC_LEVEL = `${METRICS}__metric-level`;

const defaultGraphData = [
  {
    id: 'ios',
    label: '50.0%',
    value: 50,
    color: '#056AFF',
  },
  {
    id: 'android',
    label: '50.0%',
    value: 50,
    color: '#00AB50',
  },
  {
    id: 'other',
    label: '0%',
    value: 0,
    color: '#72DAFF',
  },
];

type MetricLevelProps = {
  title: string;
  children: JSX.Element | JSX.Element[];
  header?: JSX.Element | JSX.Element[];
};

// Lines inside the Metric objects
const MetricLevel = ({ title, children, header }: MetricLevelProps) => {
  const METRIC_TITLE_CONTAINER = `${METRIC_LEVEL}__metric-level-title-container`;
  const METRIC_TITLE_TEXT = `${METRIC_TITLE_CONTAINER}__text`;
  const METRIC_DATA_CONTAINER = `${METRIC_LEVEL}__metric-data-container`;
  return (
    <div className={METRIC_LEVEL}>
      <div className={METRIC_TITLE_CONTAINER}>
        <div className={METRIC_TITLE_TEXT}>{title}</div>
        {header && <div> {header} </div>}
      </div>
      <div className={METRIC_DATA_CONTAINER}>{children}</div>
    </div>
  );
};

type GridRowProps = {
  className: string;
  children: JSX.Element | JSX.Element[];
};

const GridLayout = ({ children, className }: GridRowProps) => {
  return (
    <div style={{ display: 'grid' }} className={className}>
      {children}
    </div>
  );
};

type MetricProps = {
  title: string;
  children: JSX.Element | JSX.Element[];
  rightSide?: JSX.Element | JSX.Element[];
  header?: JSX.Element | JSX.Element[];
};

// Top level object to hold the Metric Levels
const Metric = ({ title, children, rightSide, header }: MetricProps) => {
  const METRIC = `${METRIC_LEVEL}__metric`;
  const METRIC_LEFT = `${METRIC_LEVEL}__metric__left-side`;
  const METRIC_RIGHT = `${METRIC_LEVEL}__metric__right-side`;
  const METRIC_TITLE_CONTAINER = `${METRIC}__metric-title-container `;
  const METRIC_TITLE = `${METRIC_TITLE_CONTAINER}__text`;

  return (
    <div className={METRIC}>
      <div className={METRIC_LEFT}>
        <div className={METRIC_TITLE_CONTAINER}>
          <div className={METRIC_TITLE}>{title}</div>
          {header && <div>{header}</div>}
        </div>
        {children}
      </div>
      {rightSide && <div className={METRIC_RIGHT}>{rightSide}</div>}
    </div>
  );
};

type MetricValueProps = {
  value: string;
  withDivier?: boolean | null;
  children?: undefined | JSX.Element | JSX.Element;
};

const MetricValue = ({ value, children }: MetricValueProps) => {
  const METRIC = `${METRIC_LEVEL}__metric__left-side`;
  const METRIC_VALUE_CONTAINER = `${METRIC}__metric-value-container`;
  const METRIC_VALUE = `${METRIC_VALUE_CONTAINER}__metric-value `;
  return (
    <div className={METRIC_VALUE_CONTAINER}>
      <div className={METRIC_VALUE}>{value}</div>
      {children}
    </div>
  );
};

type SubMetricValue = {
  text: string;
  svg?: string;
  chg?: number;
  className?: string;
};

const SubMetricValue = ({ text, svg, chg, className }: SubMetricValue) => {
  const METRIC = `${METRIC_LEVEL}__metric__left-side`;
  const METRIC_VALUE_CONTAINER = `${METRIC}__metric-value-container`;
  const SUB_METRIC_VALUE = `${METRIC_VALUE_CONTAINER}__sub-metric-value`;

  const SUB_METRIC_CLASSNAME = `${SUB_METRIC_VALUE}  ${className ? SUB_METRIC_VALUE + className : ''}`;
  return (
    <div className={`${SUB_METRIC_CLASSNAME}`}>
      {(svg || chg) && (
        <div className={`${SUB_METRIC_CLASSNAME}__indicator`}>
          {svg ? getIcon(svg, {}) : chg ? <div> {chg.toFixed(0) + '%'} </div> : <></>}
        </div>
      )}
      <div className={`${SUB_METRIC_CLASSNAME}__text`}>{text}</div>
    </div>
  );
};

type DeviceIndicatorProps = {
  svg: JSX.Element;
  name: string;
};

const DeviceIndicator = ({ svg, name }: DeviceIndicatorProps) => {
  const METRIC = `${METRIC_LEVEL}__metric__left-side`;
  const METRIC_VALUE_CONTAINER = `${METRIC}__metric-value-container`;
  const DEVICE_VALUE = `${METRIC_VALUE_CONTAINER}__device-indicator`;

  return (
    <div className={`${DEVICE_VALUE}`}>
      <div className={`${DEVICE_VALUE}__indicator`}>{svg}</div>
      <div className={`${DEVICE_VALUE}__text`}>{name}</div>
    </div>
  );
};

const SubMetricValueDeltaChange = (props: any, labelCopy: string): JSX.Element => {
  if (props?.change > 0) {
    return (
      <SubMetricValue
        text={`${props.change.toFixed(2) ?? 0}% ${labelCopy}`}
        svg="baselineArrowUp"
        className={'__positive-value'}
      />
    );
  } else if (props?.change == 0 || !props.change) {
    return <SubMetricValue text={`No changes ${labelCopy}`} className={'__nutural-value'} />;
  } else {
    return (
      <SubMetricValue
        text={`${Math.abs(props.change.toFixed(2))}% ${labelCopy}`}
        svg="baselineArrowDown"
        className={'__negitive-value'}
      />
    );
  }
};

type SubMetricValuePushDeviceBreakDownProps = {
  ios: number;
  android: number;
  totalDevices: number;
};

const SubMetricValuePushDeviceBreakDown = ({
  ios,
  android,
  totalDevices,
}: SubMetricValuePushDeviceBreakDownProps): JSX.Element => {
  const METRIC = `${METRIC_LEVEL}__metric__left-side`;
  const METRIC_VALUE_CONTAINER = `${METRIC}__metric-value-container`;
  const DEVICE_STACK = `${METRIC_VALUE_CONTAINER}__device-indicator-stack`;
  const DEVICE_ROW = `${DEVICE_STACK}__device-row`;

  return (
    <div className={`${DEVICE_STACK}`}>
      <div className={DEVICE_ROW}>
        <DeviceIndicator svg={<IosBlue />} name="iOS" />
        <div className={`${DEVICE_ROW}__device-row-value-container`}>
          <div className={`${DEVICE_ROW}__device-row-value-container__value`}>
            <span>
              {`${intlNumberFormat(ios)}(`}
              <span className={`${DEVICE_ROW}__device-row-value-container__value__value-percentage`}>
                {totalDevices ? `${intlNumberFormat((ios / totalDevices) * 100)}%` : `0%`}
              </span>
              <span>)</span>
            </span>
          </div>
        </div>
      </div>
      <div className={DEVICE_ROW}>
        <hr />
      </div>
      <div className={DEVICE_ROW}>
        <DeviceIndicator svg={<AndroidGreen />} name="Android" />
        <div className={`${DEVICE_ROW}__device-row-value-container`}>
          <div className={`${DEVICE_ROW}__device-row-value-container__value`}>
            <span>
              {`${intlNumberFormat(android)}(`}
              <span className={`${DEVICE_ROW}__device-row-value-container__value__value-percentage`}>
                {totalDevices ? `${intlNumberFormat((android / totalDevices) * 100)}%` : `0%`}
              </span>
              <span>)</span>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

type SubMetricValueLocationOptInBreakDownProps = {
  ios: number;
  android: number;
  other: number;
  totalDevices: number;
};

const SubMetricValueLocationOptInBreakDown = ({
  ios,
  android,
  other,
  totalDevices,
}: SubMetricValueLocationOptInBreakDownProps): JSX.Element => {
  const METRIC = `${METRIC_LEVEL}__metric__left-side`;
  const METRIC_VALUE_CONTAINER = `${METRIC}__metric-value-container`;
  const DEVICE_STACK = `${METRIC_VALUE_CONTAINER}__device-indicator-stack`;
  const DEVICE_ROW = `${DEVICE_STACK}__device-row`;

  return (
    <div className={`${DEVICE_STACK}`}>
      <div className={DEVICE_ROW}>
        <DeviceIndicator svg={<IosBlue />} name="iOS" />
        <div className={`${DEVICE_ROW}__device-row-value-container`}>
          <div className={`${DEVICE_ROW}__device-row-value-container__value`}>
            <span>
              {`${intlNumberFormat(ios)}(`}
              <span className={`${DEVICE_ROW}__device-row-value-container__value__value-percentage`}>
                {totalDevices ? `${intlNumberFormat((ios / totalDevices) * 100)}%` : `0%`}
              </span>
              <span>)</span>
            </span>
          </div>
        </div>
      </div>
      <div className={DEVICE_ROW}>
        <hr />
      </div>
      <div className={DEVICE_ROW}>
        <DeviceIndicator svg={<AndroidGreen />} name="Android" />
        <div className={`${DEVICE_ROW}__device-row-value-container`}>
          <div className={`${DEVICE_ROW}__device-row-value-container__value`}>
            <span>
              {`${intlNumberFormat(android)}(`}
              <span className={`${DEVICE_ROW}__device-row-value-container__value__value-percentage`}>
                {totalDevices ? `${intlNumberFormat((android / totalDevices) * 100)}%` : `0%`}
              </span>
              <span>)</span>
            </span>
          </div>
        </div>
      </div>
      <div className={DEVICE_ROW}>
        <hr />
      </div>
      <div className={DEVICE_ROW}>
        <DeviceIndicator svg={<WebBlue />} name="Other" />
        <div className={`${DEVICE_ROW}__device-row-value-container`}>
          <div className={`${DEVICE_ROW}__device-row-value-container__value`}>
            <span>
              {`${intlNumberFormat(other)}(`}
              <span className={`${DEVICE_ROW}__device-row-value-container__value__value-percentage`}>
                {totalDevices ? `${intlNumberFormat((other / totalDevices) * 100)}%` : `0%`}
              </span>
              <span>)</span>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default function AnalyticsOverview() {
  const analyticsExperiencesApi = useMemo(() => new AnalyticsExperiencesAPI(), []);
  const templatedExperienceAnalyticsApi = useMemo(() => new TemplatedExperienceAnalyticsAPI(), []);

  const { flags } = useFeatureFlag();
  const dispatch = useDispatch();
  const [dateRange, setDateRange] = useState<AdvancedDateRange>(() => {
    const startOfToday = new Date();
    const endOfToday = new Date();
    startOfToday.setHours(0, 0, 0, 0);
    endOfToday.setHours(23, 59, 59, 999);
    return [startOfToday, endOfToday, 0, 'day'];
  });

  const handleDateRangeChange = async (newDate: AdvancedDateRange) => {
    setDateRange(newDate);
  };

  const [totalDevices, setTotalDevices] = useState<GraphData[]>([]);
  const [pushOpenRate, setPushOpenRate] = useState<GraphData[]>([]);
  const [totalClickThroughRate, setTotalClickThroughRate] = useState<GraphData[]>([]);

  const [customModuleGraphsToRender, setCustomModuleGraphsToRender] = useState<ModuleConfig[]>();
  const {
    data: customModuleGraphs,
    isLoading: areCustomModuleGraphsLoading,
    refetch,
  } = useQuery({
    queryKey: ['customModules'],
    queryFn: async () => {
      const { data } = await templatedExperienceAnalyticsApi.getModuleConfigs({});
      return data;
    },
    retry: false,
  });
  const {
    isLoading: isAOLoading,
    isFetching: isAOFetching,
    isError: isOverviewExperiencesError,
  } = useQuery({
    queryKey: ['analytics-overview', dateRange],
    queryFn: async () => {
      const endOfDay = new Date(dateRange[1] ?? new Date());
      if (dateRange.length < 3 || (dateRange.length >= 3 && dateRange[2] === 0)) {
        // Known interval - no custom or single day
        endOfDay.setHours(23, 59, 59, 999);
      }

      const fetchedOverview = await analyticsExperiencesApi.getOverviewExperiences({
        startTime: Math.trunc((dateRange[0]?.getTime() ?? new Date().setHours(0, 0, 0, 0)) / 1000),
        endTime: Math.trunc((endOfDay?.getTime() ?? new Date().setHours(23, 59, 59, 999)) / 1000),
      });

      setOverviewExperiencesToRender(fetchedOverview);
      return fetchedOverview;
    },
    staleTime: 0,
    enabled: dateRange[0] !== null && dateRange[1] !== null,
    retry: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });
  const prepareCopyForLabelBasedOn = (range: AdvancedDateRange): string => {
    let copyForLabel = ``;
    const days = range[2];

    if (days !== undefined) {
      // predefinied range
      if (days == 0 || days == 1) {
        copyForLabel = 'over the past day';
      } else {
        copyForLabel = `over the past ${days} days`;
      }
    } else {
      // Custom range
      const startDate = range[0]?.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
      const endDate = range[1]?.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });

      copyForLabel = `from ${startDate ?? 'Invalid data'} to ${endDate ?? 'Invalid date'}`;
    }

    return copyForLabel;
  };
  const [overviewExperiencesToRender, setOverviewExperiencesToRender] = useState<TAnalyticsOverview>();
  const isOverviewLoading = useDelayedLoadingState((isAOLoading || isAOFetching) && !overviewExperiencesToRender);
  const locationOptIn = overviewExperiencesToRender?.locationOptIn;
  const locationOptInTotal = locationOptIn
    ? locationOptIn.web + locationOptIn.other + locationOptIn.android + locationOptIn.ios
    : 0;

  const OverallExperiencesDatePicker = () => {
    const HEADER_DATE = `${CLASS_ANALYTICS_OVERVIEW_DEFAULT}__header-date`;
    const DATE_TITLE = `${HEADER_DATE}__header__date__title`;
    const DROP_DOWN = `${HEADER_DATE}__header__date__dropdown`;

    return (
      <div className={HEADER_DATE}>
        <div className={DATE_TITLE}>
          {dateRange[2] === 0
            ? `${epochToDateTimeString(new Date().getTime(), 'en-US', DATE_FORMAT_OPTIONS)}`
            : `${epochToDateTimeString(dateRange[0] || 0, 'en-US', DATE_FORMAT_OPTIONS)} - ${epochToDateTimeString(
                dateRange[1] || 0,
                'en-US',
                DATE_FORMAT_OPTIONS,
              )}`}
        </div>
        <FlightDateRangePicker
          className={DROP_DOWN}
          value={dateRange}
          onChange={handleDateRangeChange}
          maxDate={new Date()}
          includeAllTime={false}
          includeToday
          isIncludeCustom
          replaceCustomRangeLabel={false}
        />
      </div>
    );
  };

  const removeCustomModule = useCallback(
    async (id: string) => {
      try {
        await templatedExperienceAnalyticsApi.removeModuleConfig(id);
        dispatch(
          showSnackbar({
            content: 'Module removed successfuly!',
            type: 'success',
          }),
        );
        refetch();
      } catch (error) {
        dispatch(
          showSnackbar({
            content: 'An error occurred while trying to remove the module',
            type: 'error',
          }),
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  useEffect(() => {
    document.title = 'Analytics | Experience Studio @ Flybits';
  }, []);
  useEffect(() => {
    if (window.location.hash === '#updated') {
      refetch();
      window.location.hash = '';
    }
    // eslint-disable-next-line
  }, [window.location.hash]);

  // Create Device breakout graph based on platform
  useEffect(() => {
    const webNumber = overviewExperiencesToRender?.totalDevices?.platformSplit.web ?? 0;

    const graphData: [GraphData] = Object.entries(overviewExperiencesToRender?.totalDevices?.platformSplit ?? {})
      .map(([name, decimal]) => {
        const value = decimal * 100;
        switch (name) {
          case 'ios':
            return {
              id: 'ios',
              label: value.toFixed(1).concat('%'),
              value: value.toFixed(1),
              color: '#056AFF',
            };
          case 'android':
            return {
              id: 'android',
              label: value.toFixed(1).concat('%'),
              value: value.toFixed(1),
              color: '#00AB50',
            };
          case 'other':
            const otherValue = value + webNumber * 100;
            return {
              id: 'other',
              label: otherValue.toFixed(1).concat('%'),
              value: otherValue.toFixed(2),
              color: '#72DAFF',
            };
          default:
            return {};
        }
      })
      .filter((e) => Object.entries(e).length > 0);

    setTotalDevices(graphData.length > 0 ? graphData : defaultGraphData);
  }, [overviewExperiencesToRender]);

  // Create push optin
  useEffect(() => {
    const webNumber = overviewExperiencesToRender?.pushOpenRate?.platformSplit.web ?? 0;

    const graphData: [GraphData] = Object.entries(overviewExperiencesToRender?.pushOpenRate?.platformSplit ?? {})
      .map(([name, decimal]) => {
        if (!decimal) return {};
        const value = decimal;
        switch (name) {
          case 'ios':
            return {
              id: 'ios',
              label: value.toFixed(1).concat('%'),
              value: value.toFixed(2),
              color: '#056AFF',
            };
          case 'android':
            return {
              id: 'android',
              label: value.toFixed(1).concat('%'),
              value: value.toFixed(2),
              color: '#00AB50',
            };
          case 'other':
            const webAndOtherValue = value + webNumber;
            return {
              id: 'other',
              label: webAndOtherValue.toFixed(1).concat('%'),
              value: webAndOtherValue.toFixed(2),
              color: '#72DAFF',
            };
          default:
            return {};
        }
      })
      .filter((e) => Object.entries(e).length > 0);

    setPushOpenRate(graphData.length > 0 ? graphData : defaultGraphData);
  }, [overviewExperiencesToRender]);

  useEffect(() => {
    const webNumber = overviewExperiencesToRender?.clickThroughRate?.platformSplit.web ?? 0;

    const graphData: [GraphData] = Object.entries(overviewExperiencesToRender?.clickThroughRate.platformSplit ?? {})
      .map(([name, decimal]) => {
        if (!decimal) return {};
        const value = decimal;
        switch (name) {
          case 'ios':
            return {
              id: 'ios',
              label: value.toFixed(1).concat('%'),
              value: value.toFixed(2),
              color: '#056AFF',
            };
          case 'android':
            return {
              id: 'android',
              label: value.toFixed(1).concat('%'),
              value: value.toFixed(2),
              color: '#00AB50',
            };
          case 'other':
            const webAndOtherValue = value + webNumber;
            return {
              id: 'other',
              label: webAndOtherValue.toFixed(1).concat('%'),
              value: webAndOtherValue.toFixed(2),
              color: '#72DAFF',
            };
          default:
            return {};
        }
      })
      .filter((e) => Object.entries(e).length > 0);

    setTotalClickThroughRate(graphData.length > 0 ? graphData : defaultGraphData);
  }, [overviewExperiencesToRender]);

  useEffect(() => {
    if (!customModuleGraphs) return;
    if (!isEqual(customModuleGraphsToRender, customModuleGraphs)) {
      setCustomModuleGraphsToRender(customModuleGraphs);
    }
    // eslint-disable-next-line
  }, [customModuleGraphs]);

  const showCustomAnalyticsModules = flags['tx_ca_custom_module'];
  const showJourneyTable = flags['tx_journey_analytics_table'];

  return (
    <>
      {!isOverviewExperiencesError ? (
        <div>
          <div className={CLASS_ANALYTICS_OVERVIEW_DEFAULT}>
            <div className={METRICS}>
              <MetricLevel title="User level">
                <GridLayout className={`${METRIC_LEVEL}__overload-grid-row`}>
                  <Metric title="Total Users">
                    {isOverviewLoading ? (
                      <Skeleton width={40} height={30} />
                    ) : (
                      <MetricValue value={intlNumberFormat(overviewExperiencesToRender?.totalUsers?.value || 0)} />
                    )}
                  </Metric>
                  <Metric title="Total users opt in to push notifications">
                    {isOverviewLoading ? (
                      <Skeleton width={40} height={30} />
                    ) : (
                      <MetricValue
                        value={intlNumberFormat(
                          (overviewExperiencesToRender?.pushOptIn?.ios ?? 0) +
                            (overviewExperiencesToRender?.pushOptIn?.android ?? 0) +
                            (overviewExperiencesToRender?.pushOptIn?.other ?? 0) +
                            (overviewExperiencesToRender?.pushOptIn?.web ?? 0),
                        )}
                      >
                        <SubMetricValue
                          text={`of total users`}
                          chg={
                            overviewExperiencesToRender?.totalUsers
                              ? (((overviewExperiencesToRender?.pushOptIn?.ios ?? 0) +
                                  (overviewExperiencesToRender?.pushOptIn?.android ?? 0) +
                                  (overviewExperiencesToRender?.pushOptIn?.other ?? 0) +
                                  (overviewExperiencesToRender?.pushOptIn?.web ?? 0)) /
                                  overviewExperiencesToRender?.totalUsers.value) *
                                100
                              : undefined
                          }
                          className={'__nutural-value'}
                        />
                      </MetricValue>
                    )}
                  </Metric>
                  <Metric title="Total users opted in to location">
                    {isOverviewLoading ? (
                      <Skeleton width={40} height={30} />
                    ) : (
                      <MetricValue value={intlNumberFormat(locationOptInTotal)}>
                        <SubMetricValue
                          text={`of total users`}
                          chg={
                            overviewExperiencesToRender?.totalUsers
                              ? (((overviewExperiencesToRender?.locationOptIn?.ios ?? 0) +
                                  (overviewExperiencesToRender?.locationOptIn?.android ?? 0) +
                                  (overviewExperiencesToRender?.locationOptIn?.web ?? 0) +
                                  (overviewExperiencesToRender?.locationOptIn?.other ?? 0)) /
                                  overviewExperiencesToRender?.totalUsers.value) *
                                100
                              : undefined
                          }
                          className={'__nutural-value'}
                        />
                      </MetricValue>
                    )}
                  </Metric>
                </GridLayout>
              </MetricLevel>
              <MetricLevel title="Device level">
                <GridLayout className={`${METRIC_LEVEL}__two-by-two-grid`}>
                  <Metric title="Total devices">
                    {isOverviewLoading ? (
                      <Skeleton width={40} height={30} />
                    ) : (
                      <MetricValue value={intlNumberFormat(overviewExperiencesToRender?.totalDevices?.value ?? 0)} />
                    )}
                  </Metric>
                  <Metric title="Device breakout based on platform" rightSide={<PieGraphDevice data={totalDevices} />}>
                    <GridLayout className={`${METRIC_LEVEL}__device-based-on-platform`}>
                      {isOverviewLoading ? (
                        <Skeleton width={40} height={30} />
                      ) : (
                        <MetricValue
                          value={intlNumberFormat(
                            overviewExperiencesToRender && overviewExperiencesToRender.totalDevices
                              ? overviewExperiencesToRender.totalDevices.value *
                                  overviewExperiencesToRender.totalDevices.platformSplit.ios
                              : 0,
                          )}
                        >
                          <DeviceIndicator svg={<IosGray />} name="iOS" />
                        </MetricValue>
                      )}
                      <hr />
                      {isOverviewLoading ? (
                        <Skeleton width={40} height={30} />
                      ) : (
                        <MetricValue
                          value={intlNumberFormat(
                            overviewExperiencesToRender && overviewExperiencesToRender.totalDevices
                              ? overviewExperiencesToRender.totalDevices.value *
                                  overviewExperiencesToRender.totalDevices.platformSplit.android
                              : 0,
                          )}
                        >
                          <DeviceIndicator svg={<AndroidGray />} name="Android" />
                        </MetricValue>
                      )}
                      <hr />
                      {isOverviewLoading ? (
                        <Skeleton width={40} height={30} />
                      ) : (
                        <MetricValue
                          value={intlNumberFormat(
                            overviewExperiencesToRender && overviewExperiencesToRender.totalDevices
                              ? overviewExperiencesToRender.totalDevices.value -
                                  (overviewExperiencesToRender.totalDevices.value *
                                    overviewExperiencesToRender.totalDevices.platformSplit.ios +
                                    overviewExperiencesToRender.totalDevices.value *
                                      overviewExperiencesToRender.totalDevices.platformSplit.android)
                              : 0,
                          )}
                        >
                          <DeviceIndicator svg={<WebGray />} name="Web & other" />
                        </MetricValue>
                      )}
                    </GridLayout>
                  </Metric>
                  <Metric title="Device push opt ins based on platform">
                    <SubMetricValuePushDeviceBreakDown
                      ios={overviewExperiencesToRender?.pushOptIn?.ios ?? 0}
                      android={overviewExperiencesToRender?.pushOptIn.android ?? 0}
                      totalDevices={
                        overviewExperiencesToRender?.totalDevices ? overviewExperiencesToRender.totalDevices.value : 0
                      }
                    />
                  </Metric>
                  <Metric title="Location opt ins based on platform">
                    <SubMetricValueLocationOptInBreakDown
                      ios={overviewExperiencesToRender?.locationOptIn?.ios ?? 0}
                      totalDevices={
                        overviewExperiencesToRender?.totalDevices ? overviewExperiencesToRender.totalDevices.value : 0
                      }
                      android={overviewExperiencesToRender?.locationOptIn.android ?? 0}
                      other={
                        (overviewExperiencesToRender?.locationOptIn.other ?? 0) +
                        (overviewExperiencesToRender?.locationOptIn.web ?? 0)
                      }
                    />
                  </Metric>
                </GridLayout>
              </MetricLevel>
              <MetricLevel title="Overview (All experiences)" header={<OverallExperiencesDatePicker />}>
                <GridLayout className={`${METRIC_LEVEL}__two-by-two-grid`}>
                  <Metric title="Total experiences launched">
                    {isOverviewLoading ? (
                      <Skeleton width={40} height={30} />
                    ) : (
                      <MetricValue
                        value={intlNumberFormat(overviewExperiencesToRender?.experiencesLaunched.value || 0)}
                      >
                        {SubMetricValueDeltaChange(
                          overviewExperiencesToRender?.experiencesLaunched,
                          prepareCopyForLabelBasedOn(dateRange),
                        )}
                      </MetricValue>
                    )}
                  </Metric>
                  <Metric title="Average engagement rate">
                    {isOverviewLoading ? (
                      <Skeleton width={40} height={30} />
                    ) : (
                      <MetricValue
                        value={`${intlNumberFormat(overviewExperiencesToRender?.avgEngagementRate.value || 0)}%`}
                      >
                        {SubMetricValueDeltaChange(
                          overviewExperiencesToRender?.avgEngagementRate,
                          prepareCopyForLabelBasedOn(dateRange),
                        )}
                      </MetricValue>
                    )}
                  </Metric>
                  <Metric title="Push open rate" rightSide={<PieGraphDevice data={pushOpenRate} />}>
                    {isOverviewLoading ? (
                      <Skeleton width={40} height={30} />
                    ) : (
                      <MetricValue value={`${intlNumberFormat(overviewExperiencesToRender?.pushOpenRate.value || 0)}%`}>
                        {SubMetricValueDeltaChange(
                          overviewExperiencesToRender?.pushOpenRate,
                          prepareCopyForLabelBasedOn(dateRange),
                        )}
                      </MetricValue>
                    )}
                  </Metric>
                  <Metric title="Click-through rate" rightSide={<PieGraphDevice data={totalClickThroughRate} />}>
                    {isOverviewLoading ? (
                      <Skeleton width={40} height={30} />
                    ) : (
                      <MetricValue
                        value={`${intlNumberFormat(overviewExperiencesToRender?.clickThroughRate.value || 0)}%`}
                      >
                        {SubMetricValueDeltaChange(
                          overviewExperiencesToRender?.clickThroughRate,
                          prepareCopyForLabelBasedOn(dateRange),
                        )}
                      </MetricValue>
                    )}
                  </Metric>
                </GridLayout>
              </MetricLevel>
            </div>
          </div>

          <div className={ENGAGEMENT}>
            <div className={ENGAGEMENT_CONTAINER.SELF}>
              <div className={ENGAGEMENT_CONTAINER.HEADER}>
                <div>Engagement</div>
                <ExportEngagement />
              </div>
              {/* Journey Analytics Table */}
              {showJourneyTable && <JourneyTable />}
              <Engagement />
              {showCustomAnalyticsModules && (
                <div className={ANALYTICS_OVERVIEW.BOTTOM_SIDE}>
                  {areCustomModuleGraphsLoading ? (
                    <LoadingState />
                  ) : (
                    <AnalyticsGraphs
                      setCustomModuleGraphs={setCustomModuleGraphsToRender}
                      customModuleGraphs={customModuleGraphsToRender}
                      handleRemoveCustomModule={removeCustomModule}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <ErrorPage />
      )}
    </>
  );
}
